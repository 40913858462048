import React from 'react';

import Mastnak from '../images/mastnak.jpg';
import Cuthbert from '../images/cuthbert.jpeg';
import OperaWorld from '../images/operaworld.png';

function Testimonials() {
  return (
    <section className="relative">

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 lg:pt-16">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 lg:pb-24">
            <h2 className="h2 mb-4">Testimonials</h2>
          </div>

          <div className="lg:grid lg:grid-cols-3 lg:gap-x-2">
            <div className="p-4 text-gray-800 rounded-lg shadow-md">
              <div className="mb-2">
                <p className="mb-2 text-center text-gray-600 italic">
                  "Peachnote is an outstandingly progressive Munich enterprise on high technology for music."
                </p>
                <div className="flex flex-col items-center justify-center">
                  <div className="w-24 h-24 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full my-6">
                    <img src={Mastnak} alt="img"
                         className="object-cover object-center w-full h-full"/>
                  </div>
                  <h5 className="font-bold">Prof. DDDr. Wofgang Mastnak</h5>
                  <p className="text-sm text-gray-600 mt-2">Music therapist, educator. Member New York Academy of Sciences, International Society for
                    Polyaesthetic Education</p>
                </div>
              </div>
            </div>
            <div className="p-4 text-gray-800 rounded-lg shadow-md">
              <div className="mb-2">
                <p className="mb-2 text-center text-gray-600 italic">
                  "Peachnote is having a transformative effect on a musicologist's ability to find patterns in huge
                  numbers of scores and changes over time that would be otherwise lost."
                </p>
                <div className="flex flex-col items-center justify-center">
                  <div className="w-24 h-24 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full my-6">
                    <img src={Cuthbert} alt="img"
                         className="object-cover object-center w-full h-full"/>
                  </div>
                  <h5 className="font-bold">Prof. Michael Scott Cuthbert</h5>
                  <p className="text-sm text-gray-600 mt-2">MIT Associate Professor, Faculty Director Digital Humanities at MIT</p>
                </div>
              </div>
            </div>
            <div className="p-4 text-gray-800 rounded-lg shadow-md">
              <div className="mb-2">
                <p className="mb-2 text-center text-gray-600 italic">
                  "Imagine going to the opera and knowing the score like a conductor. Imagine being able to switch
                  the singers and the orchestra with just a flip of your fingers. These musical superpowers are
                  now at your command with Peachnote."
                </p>
                <div className="flex flex-col items-center justify-center">
                  <div className="w-24 h-24 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full my-6">
                    <img src={OperaWorld} alt="img"
                         className="object-cover object-center w-full h-full"/>
                  </div>
                  <h5 className="font-bold">Opera World</h5>
                  <p className="text-sm text-gray-600 mt-2">International opera magazine</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Testimonials;