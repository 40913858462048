import React, {useEffect, useState} from 'react';

import Features from "./partials/Features";
import Testimonials from "./partials/Testimonials";

import './css/style.scss';

import baton from './images/hands-baton-r_vectorized.png';
import chambre from './images/Musique-de-chambre-cropped.png';
import PeachnoteLogoWhite from './images/peachnote-logotype-large-white.png';
import LanguageIcon from './images/icons/language-solid.svg';
import H2020Logo from './images/h2020logo.png';
import MitLogo from './images/mitlogo.png';
import GoogleResearchAward from './images/googleresearchaward.png';

import concertgebouw from './images/logos/concertgebouw.jpg';
import mdw from './images/logos/mdw.png';
import gold from './images/logos/gold.png';
import unige from './images/logos/unige.png';
import polimi from './images/logos/polimi.png';
import upf from './images/logos/upf.png';
import tudelft from './images/logos/tudelft.png';
import coventry from './images/logos/coventry.png';
import athena from './images/logos/athena.png';
import cemfi from './images/logos/cemfi.png';
import muziekweb from './images/logos/muziekweb.jpg';
import fjkb from './images/logos/fjkb.svg';
import karajan from './images/logos/karajan.png';
import imslp from './images/logos/imslp.png';
import motek from './images/logos/motek.png';

import AOS from 'aos';


export default function App() {

  const [isNavOpen, setIsNavOpen] = useState(false);

  const partners = [
    ['Stichting Koninklijk Concertgebouworkest', concertgebouw],
    ['Universität für Musik und darstellende Kunst Wien', mdw],
    ['Goldsmiths’ College', gold],
    ['Università di Genova', unige],
    ['Politecnico di Milano', polimi],
    ['Universitat Pompeu Fabra', upf],
    ['Center of Music and Film Informatics, HfM Detmold', cemfi],
    ['Technische Universiteit Delft', tudelft],
    ['ATHENA Research Center', athena],
    ['Coventry University', coventry],
    ['Stichting Centrale Discotheek', muziekweb],
    ['Festival junger Künstler Bayreuth', fjkb],
    ['Eliette und Herbert von Karajan Institute', karajan],
    ['Petrucci Music Library', imslp],
    ['Motek Entertainment', motek],
  ]

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  return (
      <div>
        <section className="banner-area w-full h-screen bg-gradient-to-b from-rose-500 to-orange-600" id="home">

          <div className="flex items-center justify-between py-4 text-white px-8">
            <a href="/">
              <img src={PeachnoteLogoWhite} className="header-logo h-12"/>
            </a>
            <nav>
              <section className="MOBILE-MENU flex md:hidden text-gray-600">
                <div
                    className="HAMBURGER-ICON space-y-2"
                    onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
                >
                  <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                  <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                  <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                </div>

                <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
                  <div
                      className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
                      onClick={() => setIsNavOpen(false)}
                  >
                    <svg
                        className="h-8 w-8 text-gray-600"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </div>
                  <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
                    <li className="border-b border-gray-400 my-8 uppercase">
                      <a href="#about" onClick={() => setIsNavOpen(false)}>About</a>
                    </li>
                    <li className="border-b border-gray-400 my-8 uppercase">
                      <a href="#edtech" onClick={() => setIsNavOpen(false)}>Solutions</a>
                    </li>
                    <li className="border-b border-gray-400 my-8 uppercase">
                      <a href="#testimonials" onClick={() => setIsNavOpen(false)}>Testimonials</a>
                    </li>
                    <li className="border-b border-gray-400 my-8 uppercase">
                      <a href="#contact" onClick={() => setIsNavOpen(false)}>Contact</a>
                    </li>
                  </ul>
                </div>
              </section>

              <ul className="DESKTOP-MENU hidden space-x-8 md:flex">
                <li className="float-left ml-4"><a href="#about">About</a></li>
                <li className="float-left ml-4"><a href="#edtech">Solutions</a></li>
                <li className="float-left ml-4"><a href="#testimonials">Testimonials</a></li>
                <li className="float-left ml-4"><a href="#contact">Contact</a></li>
              </ul>
            </nav>
            <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
          </div>

          <div className="container mx-auto sm:px-4 items-center h-full">
            <div className="flex flex-wrap flex items-center justify-start h-full md:pb-20">
              <div className="pt-16 lg:pt-0 grid lg:w-3/5 px-4 sm:text-center sm:place-content-center">
                <div className="uppercase text-white text-5xl sm:text-7xl sm:text-bold sm:font-bold">
                  Musical superpowers
                </div>
                <h3 className="text-white text-2xl mt-4 font-semibold">
                  Delivering AI-powered modules and applications
                </h3>
              </div>
              <div className="lg:w-2/5 pr-4 pl-4 banner-right mx-auto">
                <img className="max-w-full h-auto flipped-image" src={baton} alt=""/>
              </div>
            </div>
          </div>
        </section>

        <section className="items-center" id="about">
          <div className="container mx-auto sm:px-4">
            <div className="single-info flex flex-wrap mt-20 lg:mt-40 items-center">
              <div className="lg:w-1/2 pr-4 pl-4 md:w-full pr-4 pl-4 text-center no-padding lg:block hidden">
                <div className="info-thumb">
                  <img src={chambre} className="max-w-full h-auto info-img" alt=""/>
                </div>
              </div>

              <div className="lg:w-1/2 md:w-full no-padding lg:-translate-x-16 ">
                <div className="text-lg p-8 drop-shadow-2xl bg-amber-50 mb-8">
                  <h2 className="h2 mb-4 uppercase">Our expertise</h2>

                  We develop technologies and applications based on knowing and understanding music.
                  <br/><br/>
                  Notated, performed, practiced and experienced.
                  <br/><br/>
                  Since 2009 we have developed the world's first search engine for digitized sheet music,
                  an app that links scores with thousands of recordings measure by measure,
                  an IoT platform, apps and devices for pianos,
                  a real-time mobile movement similarity search, a tool for literary research, and much more.

                </div>

                <div
                    className="text-lg p-8 drop-shadow-2xl bg-amber-50 mb-8 md:flex md:flex-row md:justify-between items-center text-gray-800">
                  <h2 className="h4 uppercase mb-6 md:mb-0 text-center sm:text-left">Grants & Awards</h2>
                  <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="h4 flex flex-row items-center mb-4 sm:mb-0 sm:ml-4 w-60 flex-grow flex-shrink flex-basis">
                      <span>2&times;</span> <img src={H2020Logo} className={'pl-2'} title={'Two Horizon2020 grants, Wholodance and Trompa'}/>
                    </div>
                    <img src={MitLogo} className={'w-24 mb-4 sm:mb-0 sm:mx-8'} title={'MIT MISTI Award'}/>
                    <img src={GoogleResearchAward} className={'w-32'} title={'Google Research Award'}/>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>

        {false && <section className="pb-16">
          <div className="container mx-auto sm:px-4">
            <div className="flex flex-wrap mb-16 mt-24 justify-around">
              <div className="bg-amber-50 drop-shadow-2xl rounded-lg px-8 mx-8">
                <div className="text-center py-8">
                  <h1 className="font-semibold text-orange-700 text-5xl">550k+</h1>
                  <p className={'text-lg'}>Scores Indexed</p>
                </div>
              </div>
              <div className="bg-amber-50 drop-shadow-2xl rounded-lg px-8 mx-8">
                <div className="text-center py-8">
                  <h1 className="font-semibold text-orange-700 text-5xl">1M+</h1>
                  <p className={'text-lg'}>Recordings Analyzed</p>
                </div>
              </div>
              <div className="bg-amber-50 drop-shadow-2xl rounded-lg px-8 mx-8">
                <div className="text-center py-8">
                  <h1 className="font-semibold text-orange-700 text-5xl">5B+</h1>
                  <p className={'text-lg'}>Notes Indexed</p>
                </div>
              </div>
              <div className="bg-amber-50 drop-shadow-2xl rounded-lg px-8 mx-8">
                <div className="text-center py-8">
                  <h1 className="font-semibold text-orange-700 text-5xl">10M+</h1>
                  <p className={'text-lg'}>Users Served</p>
                </div>
              </div>
            </div>
          </div>
        </section>}

        <section className={"partners-area section-gap"} id={"partners"}>

          <section className="relative">

            {/* Section background (needs .relative class on parent and next sibling elements)
        <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
        <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>*/}

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-12 md:pt-20">

                {/* Section header */}
                <div className="max-w-6xl mx-auto text-center pb-16">
                  <h1 className="h2 mb-4">Partners</h1>
                  <p className="text-xl text-gray-600">
                    Trusted by leading institutions across Europe.
                    <br/>
                    Our partners include renowned universities, cultural organizations, and research centers.
                  </p>
                </div>

                {/* Partner logos */}
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2 justify-center">
                  {partners.map(function (nameUrl, i) {
                    let name = nameUrl[0];
                    let img = nameUrl[1];
                    return (
                        <div className="min-w-24 h-24 min-w-0 m-2 mx-4 outline-none" key={i}>
                          <div className="relative w-full h-full outline-none">
                            <img src={img} className="object-contain py-2 max-h-full w-full top-0 left-0 outline-none hover:opacity-75 transition ease-in-out duration-150"
                                 alt={name}
                                 title={name}
                                 tabIndex={-1}/>
                          </div>
                        </div>
                    )
                  })}
                </div>

                {/* Section footer */}
                <div className="max-w-6xl mx-auto text-center pt-16">
                  <p className="text-xl text-gray-600">
                    Join our network of esteemed partners and unlock new possibilities in music technology.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="edtech-area section-gap" id="edtech">

          <Features/>

        </section>

        <section id={"testimonials"} className={'flex flex-col justify-center'}>

          <Testimonials/>

        </section>

        <section
            className="mt-28 h-screen flex flex-col justify-center bg-gradient-to-b to-rose-600 from-orange-600 text-white"
            id="contact">
          <div className="container mx-auto px-8 py-8 border-top-generic">
            <h1 className="sm:text-center mb-8 text-white text-5xl sm:text-7xl text-bold font-bold">Make your products stand out.</h1>
            <div className="sm:text-center text-3xl mb-8">Let our cutting-edge technology and deep domain expertise work
              for you.
            </div>

            <div className="px-auto">
              <div className="flex flex-col px-auto items-center">
                <div className="text-3xl mb-8">Get in touch at hello@peachnote.com</div>
              </div>
              <div id='map' className='mapContainer'></div>
            </div>
          </div>
        </section>

        <footer className="py-4 bg-gray-900">
          <div className="container mx-auto sm:px-4">
            <p className="text-center text-gray-500">
              &copy;
              {new Date().getFullYear()}&nbsp;
              Peachnote
              <a className="ml-10" href="../impressum.html">Imprint</a>
            </p>
          </div>
        </footer>
      </div>
  )
}