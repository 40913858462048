import React, {useState, useRef, useEffect} from 'react';
import Transition from '../utils/Transition';

import PeachnoteAppTablets from '../images/peachnote-app-tablet-score.png';
import PeachnoteAppTouch from '../images/peachnote-app-tablet-touch.jfif';
import PeachnoteSimilarity from '../images/peachnote-score-similarity.png';
import ScoreMarkings from '../images/score-markings.jpg';
import RNAChopin from '../images/rna-chopin.png';
import OlympianaIphone from '../images/olympiana-iphone.png';
import WholodanceSearchDemo from '../images/wholodance-realtime-motion-demo-small.png';
import WholodanceNetworkStage from '../images/wholodance-network-stage.png';
import PerformanceEvaluation from '../images/performance-evaluation.png';
import Musician from '../images/musician.png';
import TextStudyIcon from '../images/text-study-icon.png';


function FeatureDescription(props) {
  return (
      <div className={"flex md:flex-col pr-4 mt-4 md:mt-0 md:pt-4 md:p-6 md:bg-white md:rounded-lg md:shadow-xl"}>
        <div className={"w-1/2 md:w-auto font-medium md:pt-2 pr-4"}>{props.title}</div>
        <div className={"w-1/2 md:w-auto text-base pt-0.5 md:pt-6"}>
          {props.children}
        </div>
      </div>
  );
}

function FeatureTabButton(props) {
  const [hint, setHint] = useState(false);
  return (
      <a
          className={`flex items-center text-lg p-5 rounded border-2 transition duration-300 ease-in-out mb-3 ${props.active ? 'bg-white shadow-md border-orange-300 border-2 hover:shadow-lg' : (hint ? 'bg-gray-300 border-transparent' : 'bg-gray-200 border-transparent')}`}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            props.onClick();
          }}
          onMouseOver={() => {
            setHint(true);
          }}
          onMouseOut={() => {
            setHint(false);
          }}
      >
        <div>
          <div className="font-bold leading-snug tracking-tight mb-1">{props.headline}</div>
          <div className="text-gray-600">{props.text}</div>
        </div>
      </a>
  )
}

function FeatureSectionScores() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
      <div className="md:grid md:grid-cols-12 md:gap-6">

        {/* Content */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
             data-aos="fade-right">
          <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 text-center sm:text-left">
            <h3 className="h3 mb-3">Sheet music</h3>
            <p className="text-xl text-gray-600">Scores that teach music history, theory and practice.</p>
          </div>
          {/* Tabs buttons */}
          <div className="mb-8 md:mb-0">

            <FeatureTabButton headline={'Audio on-demand'}
                              text={'Hear any place in a score in various recordings from your collections, ' +
                                  'third-party music streaming platforms and sharing services.'}
                              onClick={() => setTab(1)} active={tab === 1}/>
            <FeatureTabButton headline={'Musical connections'}
                              text={'Select a place in a score and find similar instances within the same score, ' +
                                  'by the same composer, or throughout the whole music literature.'}
                              onClick={() => setTab(2)} active={tab === 2}/>
            <FeatureTabButton headline={'Theory included'}
                              text={'Discover music theory highlights of a score and quickly find ' +
                                  'real-world examples of various theoretical concepts.'}
                              onClick={() => setTab(3)} active={tab === 3}/>

          </div>
        </div>

        {/* Tabs items */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
             data-aos="zoom-y-out" ref={tabs}>
          <div className="relative flex flex-col text-center lg:text-right pt-8">
            {/* Item 1 */}
            <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <div className="relative inline-flex flex-col">
                  <img src={PeachnoteAppTablets} className={'lg:w-3/4'}/>
                </div>
              </div>
            </Transition>
            {/* Item 2 */}
            <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={PeachnoteSimilarity} className={'lg:w-3/4 pt-24'}/>
              </div>
            </Transition>
            {/* Item 3 */}
            <Transition
                show={tab === 3}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={RNAChopin} className={'lg:w-3/4 pt-24 opacity-80'}/>
                <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 md:col-span-6 md:col-start-6 md:col-end-12 md:flex md:flex-row inline-block align-baseline items-bottom my-4">
                  <a className="h3 ml-4 mt-3 mb-3 cursor-pointer" target={'_blank'} href={'https://theory.tuttitempi.com'}>theory.TuttiTempi.com</a>
                </div>
              </div>
            </Transition>
          </div>
        </div>

      </div>
  )
}

function FeatureSectionAudio() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
      <div className="md:grid md:grid-cols-12 md:gap-6">

        {/* Content */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
             data-aos="fade-right">
          <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 text-center sm:text-left">
            <h3 className="h3 mb-3">Music recordings</h3>
            <p className="text-xl text-gray-600">Performance practice knowledge at your fingertips.</p>
          </div>
          {/* Tabs buttons */}
          <div className="mb-8 md:mb-0">
            <FeatureTabButton headline={'Discographies illuminated'}
                              text={'See differences among hundreds of interpretations of a music work at once and hear them immediately.'}
                              onClick={() => setTab(1)} active={tab === 1}/>
            <FeatureTabButton headline={'Scores for navigation'}
                              text={'Practice reading scores and hear better by seeing the currently performed bar highlighted in a score.'}
                              onClick={() => setTab(2)} active={tab === 2}/>
            <FeatureTabButton headline={'Performance analysis'}
                              text={'How are musical markings interpreted? How does harmony influence articulation? ' +
                                  'Explore commonalities and differences between thousands of performances.'}
                              onClick={() => setTab(3)} active={tab === 3}/>
            <FeatureTabButton headline={'Video intelligence'}
                              text={'Want to see how your musician colleagues play a particular tricky passage? ' +
                                  'See which concert recordings have what you want and spend time learning instead of searching.'}
                              onClick={() => setTab(4)} active={tab === 4}/>
          </div>
        </div>

        {/* Tabs items */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
             data-aos="zoom-y-out" ref={tabs}>
          <div className="relative flex flex-col text-center lg:text-right md:pt-36">
            {/* Item 1 */}
            <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 md:col-span-6 md:col-start-6 md:col-end-12 md:flex md:flex-row inline-block align-baseline items-bottom my-4">
                  <a className="h3 ml-4 mt-3 mb-3 cursor-pointer" target={'_blank'} href={'https://tuttitempi.com'}>TuttiTempi.com</a>
                </div>
                <video src="https://video.twimg.com/tweet_video/D1X-5vvWoAEvrmg.mp4" loop autoPlay muted/>
              </div>
            </Transition>
            {/* Item 2 */}
            <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <div className="relative inline-flex flex-col">
                  <img src={PeachnoteAppTouch} />
                  <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 md:col-span-6 md:col-start-6 md:col-end-12 md:flex md:flex-row inline-block align-baseline items-bottom my-4">
                    <a className="h3 ml-4 mt-3 mb-3 cursor-pointer"
                       target={'_blank'}
                       href={'https://play.google.com/store/apps/details?id=com.peachnote.android&utm_source=landing_top&utm_medium=banner&utm_campaign=landing'}>Peachnote App for Android</a>
                  </div>
                </div>
              </div>
            </Transition>
            {/* Item 3 */}
            <Transition
                show={tab === 3}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={ScoreMarkings} />
              </div>
            </Transition>
            <Transition
                show={tab === 4}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={Musician} />
              </div>
            </Transition>
          </div>
        </div>
      </div>
  )
}

function FeatureSectionDance() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
      <div className="md:grid md:grid-cols-12 md:gap-6">

        {/* Tabs items */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
             data-aos="zoom-y-out" ref={tabs}>
          <div className="relative flex flex-col text-center lg:text-right md:pt-32">
            {/* Item 1 */}
            <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={WholodanceNetworkStage} />
              </div>
            </Transition>
            {/* Item 2 */}
            <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={WholodanceSearchDemo} />
              </div>
            </Transition>

          </div>
        </div>

        {/* Content */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
             data-aos="fade-right">
          <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 text-center sm:text-left">
            <h3 className="h3 mb-3">Dance</h3>
            <p className="text-xl text-gray-600">Movement. Connected.</p>
          </div>
          {/* Tabs buttons */}
          <div className="mb-8 md:mb-0">
            <FeatureTabButton headline={'Dance practices in dialog'}
                              text={'See how movement connects choreographies across time and traditions. Move freely between video recordings of dance linked together by local similarity.'}
                              onClick={() => setTab(1)} active={tab === 1}/>
            <FeatureTabButton headline={'Show, don\'t tell'}
                              text={'Find choreographies containing motion similar to what you stream via webcam or smartphone in real-time. Up and running in 5 seconds.'}
                              onClick={() => setTab(2)} active={tab === 2}/>

          </div>
        </div>

      </div>
  )
}

function FeatureSectionMusicPractice() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
      <div className="md:grid md:grid-cols-12 md:gap-6">

        {/* Content */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6"
             data-aos="fade-right">
          <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 text-center sm:text-left">
            <h3 className="h3 mb-3">Instrument practice</h3>
            <p className="text-xl text-gray-600">Meet your musical fitness tracker.</p>
          </div>
          {/* Tabs buttons */}
          <div className="mb-8 md:mb-0">
            <FeatureTabButton headline={'Your most attentive listener'}
                              text={'Everything you play is recorded, recognized, and evaluated automatically.'}
                              onClick={() => setTab(1)} active={tab === 1}/>
            <FeatureTabButton headline={'Evaluation according to examination criteria'}
                              text={'See which grades you would get if you\'d take a particular examination today.'}
                              onClick={() => setTab(2)} active={tab === 2}/>

          </div>
        </div>

        {/* Tabs items */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
             data-aos="zoom-y-out" ref={tabs}>
          <div className="relative flex flex-col text-center lg:text-right md:pt-32">
            {/* Item 1 */}
            <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col w-full items-center">
                <img src={OlympianaIphone} className={'lg:w-3/4'} />
              </div>
            </Transition>
            {/* Item 2 */}
            <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col">
                <img src={PerformanceEvaluation} className={'w-1/2'} />
              </div>
            </Transition>

          </div>
        </div>
      </div>
  )
}


function TextStudy() {
  return (
      <div className="md:grid md:grid-cols-12">

        {/* Content */}
        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12 md:mt-6"
             data-aos="fade-right">
          <div className="md:grid md:grid-cols-12 md:align-bottom">
            <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 md:col-start-1 md:col-end-6 text-center sm:text-left">
              <h3 className="h3 mb-3">Text</h3>
              <p className="text-xl text-gray-600">Find literature by narrative elements.</p>
            </div>
            <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 md:col-span-6 md:col-start-6 md:col-end-12 flex flex-row inline-block align-baseline items-center justify-center sm:justify-start">
              <img src={TextStudyIcon} className={'w-16 h-16'}/>
              <a className="h3 ml-4 mt-3 mb-3 cursor-pointer" target={'_blank'} href={'https://text.study'}>https://Text.Study</a>
            </div>
          </div>
          {/* Tabs buttons */}
          <div className="mb-8 md:mb-0">
            <div className={"pl-4 lg:pl-0 my-4 flex"}>
              <div className={'md:grid md:grid-cols-3 md:gap-5'}>
                <FeatureDescription title={"Library"}>
                  118,000 plots of books, films and video games from English Wikipedia.
                  About 60 million words.
                </FeatureDescription>
                <FeatureDescription title={"Historical use"}>
                  See how often a trope has been used throughout the history.
                  Zoom in on search results from a specific time period.
                </FeatureDescription>
                <FeatureDescription title={"Writers' themes"}>
                  See the main themes common to the body of work of a given author.
                </FeatureDescription>
                <FeatureDescription title={"Fast search"}>
                  Quickly search using precomputed features.
                </FeatureDescription>
                <FeatureDescription title={"In-depth analysis"}>
                  Run computationally expensive analyses of texts with custom criteria specific
                  to your interests using distributed computing infrastructure.
                </FeatureDescription>
                <FeatureDescription title={"Corpus insights"}>
                  See how common a specific feature / narrative element is within the dataset.
                </FeatureDescription>
                <FeatureDescription title={"Explained results"}>
                  Let the search engine explain its results using an experimental GPT-3 integration.
                </FeatureDescription>
                <FeatureDescription title={"Crowdsourcing"}>
                  Provide feedback on the search engine results and contribute to a new public dataset
                  on which better text understanding models will be trained.
                </FeatureDescription>
                <FeatureDescription title={"Data export"}>
                  Take out all the data you have contributed to the system with one click.
                </FeatureDescription>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

function Features() {


  return (
      <section className="relative">

        {/* Section background (needs .relative class on parent and next sibling elements)
        <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
        <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>*/}

        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-12 md:pt-20">

            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-16">
              <h1 className="h2 mb-4">Solutions for edtech platforms</h1>
              <p className="text-xl text-gray-600">Create unique learning experiences and push the envelope of how engaging and efficient online education can be.</p>
            </div>

            <div className={'pb-8'}>
              <FeatureSectionScores/>
            </div>

            <div className={'py-8'}>
              <FeatureSectionAudio/>
            </div>

            <div className={'py-8'}>
              <FeatureSectionMusicPractice/>
            </div>

            <div className={'text-center text-xl italic w-full mt-8 text-gray-600'}>
              &hellip; and beyond music &hellip;
            </div>

            <div className={'py-8'}>
              <FeatureSectionDance/>
            </div>

            <div className={'py-8'}>
              <TextStudy/>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Features;
